import React, { useEffect } from "react"
import loadable from '@loadable/component'
import { useLocation } from "@reach/router"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../redux/reducers"
import { Dispatch } from "redux"
import { UserActionTypes } from "../../redux/types/user-type"
import { updateCheckoutPage } from "../../redux/actions/user/user-actions"

const CheckoutPage = loadable(() => import('./checkout'))

const CheckoutMain = (props) => {

    const { toShow, previousPath } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<Dispatch<UserActionTypes>>();
    const location = useLocation();

    useEffect(() => {
        let loc = previousPath ?? "";

        if (loc.includes("/checkout") && location.pathname.includes("/checkout")) {
            dispatch(updateCheckoutPage(true, location.pathname));
        } else if (!loc.includes("/checkout") && location.pathname.includes("/checkout")) {
            dispatch(updateCheckoutPage(props.transitionStatus === "entered", location.pathname));
        } else if (loc.includes("/checkout") && !location.pathname.includes("/checkout")) {
            dispatch(updateCheckoutPage(false, location.pathname));
        }
    }, [location, props.transitionStatus])

    return (
        <>
            <div style={{ opacity: toShow ? "1" : "0", transition: "0.3s" }}>
                <CheckoutPage/>
            </div>
        </>
    )

}

export default CheckoutMain;
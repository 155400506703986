import React  from "react"
import SEO from "../../components/seo/seo";
import CheckoutMain from "../../page-components/checkout-components/checkout-main"

const Checkout = (props) => {

    return (
        <>
            <SEO description={""} title={"Checkout"} pathname={"/checkout"}/>
            <CheckoutMain {...props}/>
        </>
    )

}

export default Checkout;